import request from './request';

export const getApi = (url:string,query:object) => {
    return request({
        url: `${url}`,
        method: 'get',
        headers: {
            platform: 'en'
        },
        params: query
    });
};

export const postApi = (url:string,data:object) => {
    return request({
        url: `${url}`,
        method: 'post',
        headers: {
            platform: 'en'
        },
        data: data
    });
};

export const getFile = (url:string,data:object) => {
    return request({
        url: `${url}`,
        responseType: 'blob',
        headers: {
            platform: 'en'
        },
        data: data
    });
};