import axios from 'axios';
import { ElMessage } from 'element-plus'
import 'element-plus/dist/index.css'

const service = axios.create({
    timeout: 30000
});

service.interceptors.request.use(
    config => {
        return config;
    },
    error => {
        console.log(error);
        return Promise.reject();
    }
);
var errorShow = false
service.interceptors.response.use(
    response => {
        if (response.status === 200) {
            if (response.data.code == 1) {
                ElMessage.error(response.data.msg)
                return response.data
            } else if (response.data.code == 1011) {
                ElMessage.error(response.data.msg)
                setTimeout(() => {
                    window.location.href = '/'
                }, 1000);
                return response.data
            }  else if (response.data.code == 8989) {
                return response.data
            } else if (response.data.code == 2) {
                ElMessage.error('接口异常！')
                setTimeout(() => {
                    window.location.href = '/'
                }, 1000);
                return response.data
            } else if (response.data.code == 1000) {
                if (!errorShow){
                    errorShow = true
                    ElMessage.error(response.data.msg)
                    // setTimeout(() => {
                    //     window.location.href = '/'
                    // }, 1000);
                    localStorage.clear()
                    
                }
                return response.data
                
            } else {
                return response.data
            }
        } else {
            ElMessage.error('接口异常！')
            setTimeout(() => {
                window.location.href = '/'
            }, 1000);
            return response.data
        }
    },
    error => {
        ElMessage.error('接口异常！')
        return Promise.reject();
    }
);

export default service;
